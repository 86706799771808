@import 'normalize.css';

body {
    font-family: georgia, serif;
    font-size: 16px;
    font-weight: 400;
    word-wrap: break-word;
    font-kerning: normal;
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    background-color: rgb(248, 253, 255);
    color: #222222;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.62671rem;
}

h3 {
    font-size: 1.38316rem;
}
h4 {
    font-size: 1rem;
}

h5 {
    font-size: 0.85028rem;
}
h6 {
    font-size: 0.78405rem;
}

@media (min-width: 400px) {
    h1 {
        font-size: 2.25rem;
    }
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
    width: 100%;
    overflow: hidden;
    height: calc(15vh + 225px);
    background-color: #2b2b2b;
}

main {
    max-width: 800px;
    margin: 0 auto;
    /* padding: 20px; */
    margin-top: 15vh;
    padding-top: calc(20px + env(safe-area-inset-top));
    padding-right: calc(20px + env(safe-area-inset-right));
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
    padding-left: calc(20px + env(safe-area-inset-left));
}

header {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-bottom: 30px;
}
header > h1 {
    margin-bottom: 0;
    color: #222222;
}
header > .profile-img {
    border: 6px solid rgb(248, 253, 255);
    border-radius: 50%;
    height: 250px;
    width: 250px;
}

@media (min-width: 700px) {
    header {
        display: flex;
        flex-direction: row;
        justify-items: flex-end;
        align-items: center;
    }
    header > h1 {
        margin-left: 30px;
        color: #fff;
    }
}

article {
    line-height: 1.6rem;
}

nav {
    font-size: 1.2rem;
}

nav li {
    margin: 10px 0;
}

nav a {
    color: rgb(26, 103, 167);
    text-decoration: none;
}

.logos-img {
    width: 100%;
}